// == PWD WP Sass Framework ==
// Theme footer styles
// Template: footer.php

@import "common/variables";
@import "common/tools";

.archive-link {
	color: $theme-color-3;
	text-align: right;

	a {
		color: inherit;
	}
}

section[class*="section"] {
	position: relative;
	padding: 6rem 0 8rem;
}

.events-section {
	background-color: #f1f1f1;
	// margin-top: 5rem;
}

.container-fluid + .hot-top-section {
	margin-top: 5rem;
}

.hot-top-section {
	background-color: #E4E4E4;
	// margin-top: 5rem;
}

section.extract-section {
	padding-bottom: 3rem;
	// margin-top: 5rem;
}

h2.section-heading {
	color: $white;
	overflow: hidden;
	margin-top: -6rem;
	transform: translate3d(0, -50%, 0);
	@include pwd-fontsize(24);
	text-transform: uppercase;

	span {
		background-color: $theme-color-1;
		display: inline-block;
		position: relative;
		padding: 1.5rem 3rem 1.5rem 0;
		border-radius: 0 0 15px 0;

		&:before {
			content: "";
			background-color: $theme-color-1;
			position: absolute;
			height: 100%;
			width: 50vw;
			top: 0;
			right: 100%;
		}
	}
}

.closest-event {
	@include media-breakpoint-up(md) {
		margin: 0 0 5px 0;
		min-height: 400px;
		height: calc(100% - 5px);
		background-color: $white;
		position: relative;

		time {
			width: 100%;
			@include media-breakpoint-up(md) {
				width: auto;
			}
			position: relative;
			font-family: $font-family-2;
			font-weight: bold;
			min-height: 100px;
			min-width: 100px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: #E7EFD0;
		}

		a {
			position: absolute;
			background-color: $white;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
			padding: 2rem;
			bottom: 20px;
			left: -5px;
		}

		.image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

.event-list__item {
	--card-img-placeholder-color: #f1f1f1;
	--card-img-placeholder: url("../images/logo-kurikka.svg");

	margin: 0 0 5px 0;
	align-items: stretch;
	background-color: $white;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
	@include media-breakpoint-down(xs) {
		font-size: 1.8rem;
	}

	time {
		width: 100%;
		max-width: 100px;

		@include media-breakpoint-up(md) {
			width: auto;
		}
		font-family: $font-family-2;
		font-weight: bold;
		min-height: 100px;
		min-width: 100px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: #E7EFD0;
	}

	a {
		padding: 1rem 2rem;
		display: inline-flex;
		align-items: center;
	}

	.image {
		background-color: var(--card-img-placeholder-color);
		background-image: var(--card-img-placeholder);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 50%;
	}
}

.hot-topic {
	background-color: $white;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
	margin: 0 0 1rem 0;

	h2 {
		color: $theme-color-1;
		font-family: $font-family-2;
		@include pwd-fontsize(30);
	}
}

.hot-topic__content {
	padding: 3rem;

	p:last-child {
		margin-bottom: 0;
	}

	a {
		color: $white;
		display: inline-block;
		background-color: $theme-color-2;
		padding: .75rem 3rem;
		border-radius: 4px;
		text-decoration: none;

		&:hover {
			background-color: darken($theme-color-2, 10%);
		}
	}
}

.hot-topic__image {
	height: 100%;

	img {
		margin: 0;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

@include media-breakpoint-up(lg) {

}
