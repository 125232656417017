// PWD WP Sass Framework 1.0 / Global settings

@import "../components/bootstrap/functions";
@import "../components/bootstrap/variables";

// == Settings - Base ==

// Do not change if you don't know what you're doing!

// Default font size in browsers, in pixels

$base-browser-font-size: 16;

// We reset font size to 10px to make it easier to define them

$base-reset-target-size: 10;

// == Settings - Typography ==

$base-font-family: "Nunito Sans", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-1: "Nunito Sans", sans-serif;
$font-family-2: "Nunito Sans", sans-serif;

// This is the default font size we want, in pixels

$base-font-size: 20;


// == Base content width ==
// This will have to match the default content width
// defined in theme.

$base-content-width: 750px;

// == Base grid container widths ==

$base-container-max-width: 1440px;
$base-container-min-width: 320px;

// == Base image directory ==

$base-image-dir: "../../dist/images/";

// == Media queries ==

$grid-default-breakpoint: lg;
$grid-print-breakpoint: md;

// == Margins ==

$base-margin: $grid-gutter-width;
$base-margin-xs: 5px;
$base-margin-md: 45px;
$base-margin-lg: 65px;
$base-content-margin: 3rem;

// Add media query debug text before body

$media-query-debug: false; // Boolean

// == Settings - Color ==

$theme-color-1: #1E1656; // Blue, dark
$theme-color-2: #906F25; // Brown
$theme-color-3: #5F4D25; // Brows, dark
$theme-color-4: #5c7b00; // Green
$theme-color-5: #B3995D; // Brown, light

$base-background-color: #ffffff;
$base-text-color: #252525;

// Link color needs darkening to match WCAG minimum
// color contrast ratio requirements
// @see https://www.w3.org/WAI/WCAG21/quickref/#contrast-minimum
$base-link-color: $theme-color-1;

$base-link-effect-color: $theme-color-4;

$base-ruler-color: #4c4c4c;

$base-row-odd-color: #f7f7f7;
$base-row-even-color: transparent;

$base-signature-text-color: #333333;

// == Settings - Content ==

$base-content-line-height: (30 * 0.05);

// == Settings - Text headings ==

// Editor supports headings <h1> to <h5>
// Font sizes are defined in pixels

$heading-font-sizes: 42,
	36,
	30,
	28,
	26,
	24;

$heading-font-sizes-xs: 35,
	33;

$heading-margins: 0 0 .8em 0,
	1.2em 0 15px 0,
	1.2em 0 15px 0,
	1.2em 0 15px 0,
	1.2em 0 10px 0,
	1.2em 0 10px 0;

$heading-color: $base-text-color;
$heading-colors: $theme-color-1,
	$heading-color,
	$heading-color,
	$heading-color,
	$heading-color,
	$heading-color;

$heading-font-family: $font-family-1;
$heading-font-families: $heading-font-family,
	$heading-font-family,
	$heading-font-family,
	$heading-font-family,
	$heading-font-family,
	$heading-font-family;

$heading-font-weight: 400;
$heading-font-weights: 400,
	400,
	$heading-font-weight,
	$heading-font-weight,
	$heading-font-weight,
	$heading-font-weight;

$heading-font-style: normal;
$heading-font-styles: $heading-font-style,
	$heading-font-style,
	$heading-font-style,
	$heading-font-style,
	$heading-font-style,
	$heading-font-style;

$heading-line-height: 1.2;
$heading-line-heights: $heading-line-height,
	$heading-line-height,
	$heading-line-height,
	$heading-line-height,
	$heading-line-height,
	$heading-line-height;

// == Settings - Buttons & inputs ==

$base-input-text-padding: 11px 12px;
$base-input-text-border: 2px solid #4c4c4c;

$base-button-padding: 12px 16px;
$base-button-color: #ffffff;
$base-button-background: $theme-color-1;
$base-button-hover-background: darken($theme-color-1, 10%);
$base-button-border: 0;
