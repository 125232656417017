// Get base font size, set to <html> element
// By default we use relative size from browser default (16px)
// and target is is specified in $reset-target-size

@use "sass:math";

@mixin pwd-base-fontsize() {
	$font-size: percentage(math.div($base-reset-target-size, $base-browser-font-size));

	font-size: $font-size;
	-webkit-text-size-adjust: $font-size;
	-ms-text-size-adjust: $font-size;
}

// Get relative font size (rem) from px value
// If you want just pixel size, set $fixed to true
// http://snook.ca/archives/html_and_css/font-size-with-rem

@mixin pwd-fontsize($px:$base-font-size, $fixed:false) {
	@if $fixed == false {
		font-size: math.div($px, $base-reset-target-size) * 1rem;
	} @else {
		font-size: $px * 1px;
	}
}

// Text heading style
// Gets individual text heading style
// Supported properties: margin, color, font-family, font-size, font-weight, font-style, line-height

// $heading-level		Heading level (int)
// $property			CSS property (string)

@mixin pwd-heading-style($heading-level:false, $property:false) {
	@if $heading-level and $property {
		@if $property == margin {
			margin: nth($heading-margins, $heading-level);
		}
		@if $property == color {
			color: nth($heading-colors, $heading-level);
		}
		@if $property == font-family {
			font-family: nth($heading-font-families, $heading-level);
		}
		@if $property == font-size {
			@include pwd-fontsize(nth($heading-font-sizes, $heading-level));
		}
		@if $property == font-weight {
			font-weight: nth($heading-font-weights, $heading-level);
		}
		@if $property == font-style {
			font-style: nth($heading-font-styles, $heading-level);
		}
		@if $property == line-height {
			line-height: nth($heading-line-heights, $heading-level);
		}
	}
}

// Fluid typography
// https://css-tricks.com/snippets/css/fluid-typography/

@function strip-unit($value) {
	@return math.div($value, $value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			@include pwd-fontsize(strip-unit($min-font-size));
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				@include pwd-fontsize(strip-unit($max-font-size));
			}
		}
	}
}

@mixin grid-spans {
	--span-33: span 12;
	--span-100-33: span 12;

	@include media-breakpoint-up(sm) {
		--span-33: span 6;
	}

	@include media-breakpoint-up(lg) {
		--span-33: span 4;
		--span-100-33: span 4;
	}
}

// == Bootstrap ==
// http://getbootstrap.com

// Grid

@import "../components/bootstrap/mixins/breakpoints";
@import "../components/bootstrap/mixins/grid-framework";
@import "../components/bootstrap/mixins/grid";

// Mixins

@import "../components/bootstrap/mixins/border-radius";
@import "../components/bootstrap/mixins/box-shadow";
@import "../components/bootstrap/mixins/transition";
@import "../components/bootstrap/mixins/image";
@import "../components/bootstrap/mixins/clearfix";

@import "../components/bootstrap/utilities/spacing";
@import "../components/bootstrap/utilities/display";
